import React from "react";
import GetStrartedImage from "../../../assets/images/get-started-svg.svg";

const get_started = (
  <div
    id="get-started"
    className="d-flex align-items-center"
    style={{ backgroundImage: 'url("' + GetStrartedImage + '")' }}
  >
    <div className="get-started-main-container">
      <div className="row get-started__container ml-0 mr-0">
        <div className="col text-center get-started__sub-container  d-flex justify-content-center align-items-center">
          <div>
            <div className="w-100 d-flex justify-content-center get-extension__header">
              <h1 className="mt-5 font-weight-bold text-white get-started__header-desktop align-center">
                Get started
              </h1>
            </div>
            <div className="d-flex justify-content-center">
              <div
                className="get-extension-btn__container row mt-lg-3 mt-sm-0"
                style={{ maxWidth: "100vw", width: "max-content" }}
              >
                <div className="col mt-2 d-flex justify-content-center pl-1 pr-1">
                  <div
                    className="get-extension-btn justify-content-center align-items-center d-flex"
                    onClick={() => {
                      window.location = "/account/campaign_manager/new";
                    }}
                  >
                    Create Campaign
                  </div>
                </div>
                <div className="col mt-2 d-flex justify-content-center pl-1 pr-1">
                  <div
                    className="talk-to-sales-btn btn btn-sm transition-3d-hover d-flex justify-content-center align-items-center"
                    onClick={() => {
                      window.location = "/contact";
                    }}
                  >
                    Talk to sales
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default get_started;
