import React from "react";
import Slider from "react-slick";
import DoubleQuote from "../../../assets/images/testimonial-double-quote.svg";

const Testimonials = () => {
  const settings = {
    className: "js-slick-carousel u-slick u-slick-zoom u-slick--gutters-3",
    dots: true,
    slidesToShow: 3,
    centerMode: false,
    autoplay: false,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section id="testimonials">
      {/* <div> */}
      <div>
        <h2 className="text-center">What our clients say</h2>
        <div className="container u-space-3 mt-3">
          <Slider {...settings}>
            <div className="js-slide my-4 client-container">
              <div className="u-slick-zoom__slide">
                <img src={DoubleQuote} />
                <div style={{ marginLeft: "-0rem" }} className='mt-2'>
                  <p className="mb-0 client-words">
                    "Accountstory helped me find who the decision maker was for
                    a company we historically have had trouble closing. Once we
                    were talking to the right person, the process was easy."
                  </p>
                </div>
                <p className="small mb-0" style={{ marginLeft: "-0rem" }}>
                  Account Executive
                </p>
              </div>
            </div>

            <div className="js-slide my-4 client-container">
              <div className="u-slick-zoom__slide">
                <img src={DoubleQuote} />
                <div style={{ marginLeft: "-0rem" }} className='mt-2'>
                  <p className="mb-0 client-words">
                    "A company I was working needed me to fill out their
                    security questionnaire. I saw on Accountstory that being
                    part of the Vendor Security Alliance could bypass that step.
                    I pushed back and the buyer dropped the security form,
                    saving me weeks."
                  </p>
                </div>
                <p className="small mb-0" style={{ marginLeft: "-0rem" }}>
                  Account Executive
                </p>
              </div>
            </div>

            <div className="js-slide my-4 client-container">
              <div className="u-slick-zoom__slide">
                <img src={DoubleQuote} />
                <div style={{ marginLeft: "-0rem" }} className='mt-2'>
                  <p className="mb-0 client-words">
                    "Being able to see how long sales cycles have taken with
                    other reps has greatly helped me and my team with quarterly
                    forecasting."
                  </p>
                </div>
                <p className="small mb-0" style={{ marginLeft: "-0rem" }}>
                  Strategic Sales Representative
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
};

export default Testimonials;
