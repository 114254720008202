import React from "react";
import PagerDuty from "../../../assets/images/pager-duty.svg";
import Upwork from "../../../assets/images/upwork.svg";
import RocketLawyer from "../../../assets/images/rocketlawyer.svg";
import Xerox from "../../../assets/images/xerox.svg";
import Applause from "../../../assets/images/applause.svg";
import Udemy from "../../../assets/images/udemy.svg";

const companies = (
  <section
    className="u-bg-img-hero d-flex align-items-center align-items-center"
    id="sought-after-companies"
  >
    <div className="container u-space-3">
      <div className="row pt-2">
        <div className="col-12 mb-5 client-header__container">
            <h2 className="client-heading text-center">OUR CLIENTS</h2>
            <h2 className="companies-heading mb-3 text-center">
              Trusted by hundreds of sellers at fast growing companies
            </h2>
        </div>
        <div className="col-6 col-sm-3 col-md-2 mb-2 mt-13 min-width-max-content">
          <div className="text-center px-lg-3 companies-logo">
            <img src={PagerDuty} alt="review" className="pager-duty-logo"/>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-md-2 mb-2 min-width-max-content">
          <div className="text-center px-lg-3 pt-3 companies-logo">
            <img src={Upwork} alt="review" className="upwork-logo"/>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-md-2 mb-2 mt-13 min-width-max-content">
          <div className="text-center px-lg-3 companies-logo">
            <img src={RocketLawyer} alt="review" className="rocket-lawyer-logo" />
          </div>
        </div>
        <div className="col max-width-max-content mb-2 mt-13">
          <div className="text-center px-lg-3 companies-logo">
            <img src={Xerox} alt="review" className="xerox-logo"/>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-md-2 mb-2 mt-13 min-width-max-content">
          <div className="text-center px-lg-3 companies-logo">
            <img src={Applause} alt="review" className="applause-logo" />
          </div>
        </div>
        <div className="col mb-2 max-width-max-content">
          <div className="text-center px-lg-3 pt-3 companies-logo">
            <img src={Udemy} alt="review" className="udemy-logo" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default companies;
