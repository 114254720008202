import React from "react";
import MainBannerImage from "../../../assets/images/main-banner-image.png";
import PagerDuty from "../../../assets/images/pager-duty.svg";
import Upwork from "../../../assets/images/upwork.svg";
import RocketLawyer from "../../../assets/images/rocketlawyer.svg";
import Xerox from "../../../assets/images/xerox.svg";
import Applause from "../../../assets/images/applause.svg";
import Udemy from "../../../assets/images/udemy.svg";

const main_banner = (
  <main id="content" role="main">
    <div className="arc-container-1" />
    <div className="arc-container-2" />

    <div className="position-relative main-section-wrapper linear-gradient">
      <div className="container pt-0">
        <div className="main-section-wrapper__content row justify-content-center">
          <div className="col">
            <div className="w-100 main-banner-header d-flex justify-content-center">
              <div>
                <center>
                  AI powered sales tools to help{" "}
                  <highlight-text>grow</highlight-text> your business
                </center>
              </div>
            </div>
            <div className="w-100 main-banner-description d-flex justify-content-center">
              <div>
                <center>
                  Accountstory helps SMBs, Mid Market, and Enterprise companies
                  grow. We help you scale outbound GTM efforts and give you deep
                  insights into target accounts.
                </center>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center learn-request-container">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <a className="learn-more-btn d-flex justify-content-center align-items-center float-left" href="#description">
                    Learn More
                  </a>
                </div>
                <div className="col d-flex justify-content-center">
                  <a className="request-demo-btn d-flex justify-content-center align-items-center" href="/contact">
                    Request Demo
                  </a>
                </div>
              </div>
            </div>
            <div className="w-100 main-banner-companies">
              <div className="w-100 d-flex justify-content-center align-items-center mt-5">
                Trusted by hundreds of sellers at fast-growing companies
              </div>
              <div className="w-100 d-flex justify-content-center mt-4 trusted-companies-logo-container">
                <div className="row">
                  <div className="col">
                    <img src={PagerDuty} />
                  </div>
                  <div className="col">
                    <img src={Upwork} />
                  </div>
                  <div className="col">
                    <img src={RocketLawyer} />
                  </div>
                  <div className="col">
                    <img src={Xerox} />
                  </div>
                  <div className="col">
                    <img src={Applause} />
                  </div>
                  <div className="col">
                    <img src={Udemy} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
);

export default main_banner;
