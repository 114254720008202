import React from "react";
import PropTypes from 'prop-types';
import "../../assets/styles/Home/index.scss";
import { Footer } from "../../components/footer";
import Header  from "../../components/header";
import { LandingPageContent } from "./content";


const HomePage = ({ userData, dataPoll}) => {
  return (
    <React.Fragment>
      <Header userData={userData}  />
      <LandingPageContent
        dataPoll={dataPoll}
      />
      <Footer />
    </React.Fragment>
  );
};

HomePage.propTypes = {
  userData: PropTypes.object.isRequired,
  dataPoll: PropTypes.number.isRequired,
}

export default HomePage;
