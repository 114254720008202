import React from "react";
import AccountForClosing from "../../../assets/images/account-for-closing.png";
import CampaignManager from "../../../assets/images/campaign-manager-home.svg";

const description = (
  <section id="description">
    <div className="container desc-part-1">
      <div className="row">
        <div className="col-md-12 header-spacing text-center">
          <h2 className="product-heading">OUR PRODUCTS</h2>
          <h2 className="product-sub-heading font-weight-bold text-center">
            Tools for prospecting, lead generation and account strategy
          </h2>
        </div>
        <div className="col-md-6 my-5 our-products__image">
          <img
            src={CampaignManager}
            alt="accountstory for prospecting"
          />
        </div>
        <div className="col-md-6 pl-md-6 my-lg-8 p-desc-wrapper">
          <div className="p-description">
            <h2 className="font-weight-bold">Campaign Manager</h2>
            <p className="p-black lead mt-4 mb-6">
              Save time prospecting. Accountstory uses AI to help you build pipeline with your target audience. Use our campaign manager product to automatically create outbound campaigns and scale them to get results.
            </p>
            <a
              className="btn-sm btn-primary text-center learn-more"
              href="/campaign"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="description-part-2 mb-6 mt-sm-3 my-lg-7">
      <div className="container pt-4">
        <div className="row">
          <div className="col-md-6 py-4 desc-img--mobile">
            <img
              src={AccountForClosing}
              alt="accountstory for closing"
              className="float-start"
            />
          </div>
          <div className="col-md-6 desc-text">
            <h2 className="pt-3 pr-md-5 font-weight-bold">
              Accountstory Insights
            </h2>
            <p className="mt-4 mb-6 lead">
              For Mid Market and Enterprise clients, get unprecedented data on
              the buying process of target accounts. Understand the procurement
              process, security review, and get other tailored insights and
              stories.
            </p>
            <a
              className="btn-sm btn-primary text-center learn-more"
              href="/insights"
            >
              Learn More
            </a>
          </div>
          <div className="col-md-6 py-4 desc-img--desktop">
            <img
              src={AccountForClosing}
              alt="accountstory for closing"
              className="float-start"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default description;
