import React from "react";
import main_banner from "./MainBanner";
import companies from "./Companies";
import description from "./Description";
import how_it_works from "./HowItWorks";
import Testimonials from "./Testimonials";
import get_started from "./GetStarted";

export const LandingPageContent = (props) => {
  return (
    <React.Fragment>
      {main_banner}
      {/* {companies} */}
      {description}
      {/* {how_it_works} */}
      <Testimonials />
      {get_started}
    </React.Fragment>
  );
};
