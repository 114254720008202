import React from "react";
import OurProcess from "../../../assets/images/our-process.svg";
import UniqueInsights from "../../../assets/images/unique-insights.svg";
import DataFingerTips from "../../../assets/images/data-finger-tips.svg";

const how_it_works = (
  <section className="u-bg-img-hero" id="how-it-works">
    <div className="container u-space-3">
      <div className="row pt-5 px-4">
        <div className="col-12 mb-5">
          <center>
            <h2 className="mb-5">How it works</h2>
          </center>
        </div>
        <div className="row mx-lg-7">
          <div className="col-md-4 mb-7">
            <div className="text-center">
              <div className="pr-lg-9">
                <img src="/assets/img/process.jpg" height="60" />
                <h3 className="my-3">Our Process</h3>
              </div>
              <p className="mb-md-0 text-left">
                We get insights from a variety of sources. We scour the web,
                crowdsource anonymous data from sellers, and get first hand
                feedback from buyers at accounts.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-7">
            <div className="text-center px-lg-3">
              <div className="pr-lg-9">
                <img src="/assets/img/insight.jpg" height="60" />
                <h3 className="my-3">Unique Insights</h3>
              </div>
              <p className="mb-md-0 text-left">
                We verify that all the information is authentic. We then surface
                key data points on your target accounts that you won’t find
                anywhere else.
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-7">
            <div className="text-center px-lg-3">
              <img src="/assets/img/Click.jpg" height="60" />
              <h3 className="my-3">Data at your Finger Tips</h3>
              <p className="mb-md-0 text-left">
                Get this information wherever you work. We make the information
                available in your workflow through our Chrome Extension for
                LinkedIn and our web app.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const how_it_work = (
  <section
    className="u-bg-img-hero d-flex  align-items-center"
    id="how-it-works"
  >
    <div className="container u-space-3">
      <div className="row justify-content-center how-it-works__container">
        <div className="col-md-5 how-it-works__section1">
          <h2 className="how-it-works__heading">HOW IT WORKS</h2>
          <p className="how-it-works__description">
            Bring crowdsourced sales intelligence to your workflow
          </p>
        </div>
        <div className="col-md-7 how-it-works__section2">
          <div className="row">
            <div className="col-md-2 pt-2 d-flex justify-content-center align-items-baseline how-it-works__image-container">
              <img src={OurProcess} />
            </div>
            <div className="col section2__second-col how-it-works__content-container">
              <h2>Our Process</h2>
              <p>
                We get insights from a variety of sources. We scour the web,
                crowdsource anonymous data from sellers, and get first hand
                feedback from buyers at accounts.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 pt-2 d-flex justify-content-center align-items-baseline how-it-works__image-container">
              <img src={UniqueInsights} />
            </div>
            <div className="col section2__second-col how-it-works__content-container">
              <h2>Unique Insights</h2>
              <p>
                We verify that all the information is authentic. We then surface
                key data points on your target accounts that you won’t find
                anywhere else.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 pt-2 d-flex justify-content-center align-items-baseline how-it-works__image-container">
              <img src={DataFingerTips} />
            </div>
            <div className="col section2__second-col how-it-works__content-container">
              <h2>Data at your Finger Tips</h2>
              <p>
                Get this information wherever you work. We make the information
                available in your workflow through our Chrome Extension for
                LinkedIn and our web app.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default how_it_work;
